<template>
  <div class="economic-concepts">
    <div class="top-space-to-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12 removeInMobile">
                <H4>Begrepp</H4>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12 removeInMobile">
                <H4>Förklaring</H4>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12 removeInMobile">
                <H4>Källa</H4>
              </div>
            </div>
            <div class="separator removeInMobile"></div>
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <p class="concept first-concept">Eget kapital</p>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <p class="explanation">
                  Eget kapital är en post i varje företags balansräkning. Som namnet antyder handlar det om den del av företagets kapital som är företagets egen (den motsvaras alltså inte av skulder). Det egna kapitalet är matematiskt lika med tillgångarna minus skulderna. Man kan också uttrycka det som att det egna kapitalet är företagets nettoförmögenhet. Det egna kapitalet består av det kapital som ägarna skjutit till (som insatskapital (aktiekapital) vid starten och som kapitaltillskott
                  under driften) och de vinstmedel som ägarna beslutat att låta vara kvar i verksamheten.
                </p>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <a target="blank" href="https://www.bjornlunden.se/f%C3%B6retagande/vad-%C3%A4r-eget-kapital__274">www.bjornlunden.se</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <p class="concept">Aktiekapital</p>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <p class="explanation">
                  När man ska starta aktiebolag tillskjuter aktiebolagets ägare aktiekapital till bolaget. I utbyte mot aktiekapitalet får ägarna aktier som bevis för ägarinnehavet i bolaget. Det lägsta tillåtna aktiekapitalet är 25 000 kronor för privata aktiebolag och 500 000 kronor för publika aktiebolag. Aktiekapitalet kan tillskjutas i form av kontanta medel eller genom apportegendom. Som huvudregel begränsas aktieägarnas ansvar för aktiebolagets skulder och andra åtaganden till det insatta
                  aktiekapitalet. När aktiekapitalet har betalats in till banken, utfärdar banken ett likvidintyg som bekräftar att inbetalningen avser aktiekapital. Detta intyg ska sedan skickas till Bolagsverket.<br /><br />
                  Aktiekapital kan senare ökas genom nyemission och fondemission. Aktiekapital måste inte motsvaras av pengar, utan kan även tillföras i form av annan tillgång om detta tillåtits i bolagets stiftelseurkund. Detta kallas apportegendom och kan utgöras av exempelvis maskiner, fastigheter eller patent. När apportegendom används som aktiekapital måste en auktoriserad eller godkänd revisor skriva ett yttrande till Bolagsverket, där det bland annat ska intygas att värderingen av
                  egendomen gått till på rätt sätt.
                </p>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <a target="blank" href="https://vismaspcs.se/ekonomiska-termer/vad-ar-aktiekapital">www.vismaspcs.se</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <p class="concept">Förbrukat eget kapital</p>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <p class="explanation">
                  Om du till exempel i ett nystartat bolag har ett aktiekapital på 50 000 kronor, kostnader som uppgår till 28 000 kronor och inga intäkter – du har då förbrukat hälften av ditt aktiekapital och befinner dig i en situation som kallas förbrukat eget kapital.
                </p>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <a target="blank" href="https://vismaspcs.se/ekonomiska-termer/vad-ar-aktiekapital">www.blogg.pwc.se</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <p class="concept">Bundet och fritt - Eget kapital</p>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <p class="explanation">
                  I aktiebolag skiljer man mellan bundet och fritt eget kapital. Bundet kapital är den del av det egna kapitalet som inte får användas för vinstutdelning. Till bundet kapital räknas t ex aktiekapital, reservfond och uppskrivningsfond. Fritt eget kapital omfattar summan av balanserade resultat (dvs inrullade resultat från tidigare år), vinst eller förlust för det senaste räkenskapsåret och kapitaltillskott från ägarna. Om det totala fria egna kapitalet blir negativt, används
                  benämningen ansamlad förlust.
                </p>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <a target="blank" href="https://www.bjornlunden.se/f%C3%B6retagande/vad-%C3%A4r-eget-kapital__274">www.bjornlunden.se</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <p class="concept">Anläggningstillgångar</p>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <p class="explanation">
                  Anläggningstillgångar är tillgångar som bedöms vara avsedda att stadigvarande användas eller ägas i företaget. Det kan även vara tillgångar avsedda för uthyrning i verksamheten.
                </p>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <a target="blank" href="https://www.bjornlunden.se/bokf%c3%b6ring/anl%c3%a4ggningstillg%c3%a5ngar__616">www.bjornlunden.se</a>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-2 col-sm-12">
                <p class="concept">Finansiella anläggningstillgångar</p>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-12">
                <div class="explanation">
                  Finansiella anläggningstillgångar är en post i balansräkningen, som omfattar följande delposter:<br /><br />
                  <ul>
                    <li>andelar i koncernföretag</li>
                    <li>fordringar hos koncernföretag</li>
                    <li>andelar i intresseföretag</li>
                    <li>fordringar hos intresseföretag</li>
                    <li>andra långfristiga värdepappersinnehav</li>
                    <li>lån till delägare och närstående</li>
                    <li>andra långfristiga fordringar</li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12">
                <a target="blank" href="https://www.bjornlunden.se/bokslut--%c3%a5rsredovisning/finansiella-anl%c3%a4ggningstillg%c3%a5ngar__1016">www.bjornlunden.se</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <p class="concept">Yield On Cost (YOC)</p>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12">
            <p class="explanation">
              Nyckeltalet Yield On Cost (YOC) är ett mått på hur stor bolagets utdelning är i relation till ditt genomsnittliga inköpspris (GAV) på ditt aktieinnehav i det bolaget.
              Du beräknar Yield On Cost genom att dividera utdelningen med ditt inköpspris, och får då ett procentuellt värde som visar hur stor utdelningen är i relation till ditt GAV.<br><br>
              YOC är egentligen detsamma som direktavkastning, men skillnaden är att YOC beräknas på inköpspriset och direktavkastningen på den aktuella
              aktiekursen. Lite förenklat man kan därför säga att Yield On Cost är direktavkastningen på den ursprungliga investeringen i den specifika aktien.
            </p>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12">
            <a target="blank" href="https://aktiewiki.se/yield-on-cost/">www.aktiewiki.se</a>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <p class="concept">P/B-tal <br>(price-to-book)</p>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12">
            <p class="explanation">
              P/B är en förkortning av engelskans price-to-book value och kan översättas till pris per aktie/eget kapital per aktie.
              P/B-tal fungerar alltså som ett sätt att jämföra aktiepriset på börsen mot bolagets <i>eget kapital</i>.<br><br>
              P/B-värdet räknas ut genom att dividera aktiekursen med eget kapital per aktie.     
            </p>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12">
            <a target="blank" href="https://aktiewiki.se/yield-on-cost/">www.aktiewiki.se</a>
          </div>
        </div>




        <!--
        <table class="table table-striped table-responsive-sm">               
          <thead>
            <tr>
                <th>Begrepp</th>
                <th>Förklaring</th>
                <th>Källa</th>            
            </tr>
          </thead>      
          <tbody>
            <tr>
                <td>Eget kapital</td>
                <td>Eget kapital är en post i varje företags balansräkning. Som namnet antyder handlar det om den del av företagets kapital som är företagets egen (den motsvaras alltså inte av skulder). 
                    Det egna kapitalet är matematiskt lika med tillgångarna minus skulderna. Man kan också uttrycka det som att det egna kapitalet är företagets nettoförmögenhet.
                    Det egna kapitalet består av det kapital som ägarna skjutit till (som insatskapital (aktiekapital) vid starten och som kapitaltillskott under driften)
                    och de vinstmedel som ägarna beslutat att låta vara kvar i verksamheten.
                </td>
                <td>
                    <ul>
                        <li>
                        <b-link to="https://www.bjornlunden.se/f%C3%B6retagande/vad-%C3%A4r-eget-kapital__274">www.bjornlunden.se</b-link>
                        </li>
                    </ul>
                </td>             
            </tr>
             <tr>
                <td>Aktiekapital</td>
                <td>När man ska starta aktiebolag tillskjuter aktiebolagets ägare aktiekapital till bolaget. I utbyte mot aktiekapitalet får ägarna aktier som bevis för ägarinnehavet i bolaget.
                    Det lägsta tillåtna aktiekapitalet är 25 000 kronor för privata aktiebolag och 500 000 kronor för publika aktiebolag.
                    Aktiekapitalet kan tillskjutas i form av kontanta medel eller genom apportegendom.
                    Som huvudregel begränsas aktieägarnas ansvar för aktiebolagets skulder och andra åtaganden till det insatta aktiekapitalet.
                    När aktiekapitalet har betalats in till banken, utfärdar banken ett likvidintyg som bekräftar att inbetalningen avser aktiekapital.
                    Detta intyg ska sedan skickas till Bolagsverket.<br/><br/>
                    Aktiekapital kan senare ökas genom nyemission och fondemission. Aktiekapital måste inte motsvaras av pengar, utan kan även tillföras i form av annan tillgång om detta tillåtits i bolagets stiftelseurkund.
                    Detta kallas apportegendom och kan utgöras av exempelvis maskiner, fastigheter eller patent.
                    När apportegendom används som aktiekapital måste en auktoriserad eller godkänd revisor skriva ett yttrande till Bolagsverket, där det bland annat ska intygas att värderingen av egendomen gått till på rätt sätt.
                </td>
                <td>
                    <ul>
                        <li>
                        <b-link to="https://vismaspcs.se/ekonomiska-termer/vad-ar-aktiekapital">www.vismaspcs.se</b-link>
                        </li>
                    </ul>
                </td>             
            </tr>
             <tr>
                <td>Förbrukat eget kapital</td>
                <td>Om du till exempel i ett nystartat bolag har ett aktiekapital på 50 000 kronor, kostnader som uppgår till 28 000 kronor och inga intäkter
                    – du har då förbrukat hälften av ditt aktiekapital och befinner dig i en situation som kallas förbrukat eget kapital.
                </td>
                <td>
                    <ul>
                        <li>
                        <b-link to="https://blogg.pwc.se/foretagarbloggen/forbrukat-eget-kapital-vad-ar-det">www.blogg.pwc.se</b-link>
                        </li>
                    </ul>
                </td>             
            </tr>
            <tr>
                <td>Bundet och fritt - Eget kapital</td>
                <td>I aktiebolag skiljer man mellan bundet och fritt eget kapital. Bundet kapital är den del av det egna kapitalet som inte får användas för vinstutdelning.
                    Till bundet kapital räknas t ex aktiekapital, reservfond och uppskrivningsfond.
                    Fritt eget kapital omfattar summan av balanserade resultat (dvs inrullade resultat från tidigare år), vinst eller förlust för det senaste räkenskapsåret och kapitaltillskott från ägarna.
                    Om det totala fria egna kapitalet blir negativt, används benämningen ansamlad förlust.
                </td>
                <td>
                    <ul>
                        <li>
                        <b-link to="https://www.bjornlunden.se/f%C3%B6retagande/vad-%C3%A4r-eget-kapital__274">www.bjornlunden.se</b-link>
                        </li>
                    </ul>
                </td>             
            </tr>
          </tbody>        
        </table> -->
      </div>
    </div>
  </div>
</template>

<script>
import "../../src/styles/_layout.scss";
import "../../src/styles/_economic-concepts.scss";

export default {
  name: "EconomicConcepts",

  data: function() {
    return {};
  },

  methods: {},
};
</script>
